import React, { useContext } from "react";

import { Link } from "react-router-dom";
import "./MobileHeader.css";
import { GlobalInfo } from "../../Shared/Modals";

const MobileHeader = () => {
  const { sidebar, hideSidebar } = useContext(GlobalInfo);
  return (
    <div className="mobile-header">
      <div className="mobile-logo-container">
        <Link to="/index">
          <img src="logo.png" alt="Logo" />
        </Link>
      </div>
      <div className="mobile-menu-icon-container">
        <h1 className="menu-text">Menu</h1>
        <div className="hamburger-container">
          <div className="bars-icon">
            <img
              className="w-7"
              onClick={() => hideSidebar(sidebar)}
              src="images/menu-bar.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileHeader;
