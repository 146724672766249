import { useContext } from "react";
import "./Sidebar.css";
import Navmenu from "./Navmenu/Navmenu";

import { Link, useNavigate } from "react-router-dom";
import { sendWoogiEvent } from "../../services/api";
import useIpad from "../Shared/useIpad";
import { hardLogout } from "../../lib";
import { GlobalInfo } from "../Shared/Modals";
import { useAuthStore } from "../../store/auth";
import { useUIDataStore } from "../../store/ui";

const Sidebar = () => {
  const { sidebar, hideSidebar } = useContext(GlobalInfo);
  const navigate = useNavigate();
  const authenticatedUser = useAuthStore((store) => store.userAuth);
  const logout = useAuthStore((store) => store.logout);
  const role = authenticatedUser?.user?.role;
  const loaded = useUIDataStore((store) => store.setLoader);
  const { isIpad } = useIpad();
  const { setModalController } = useContext(GlobalInfo);

  const navToData = () => {
    try {
      window.location.href =
        "https://mdashboard.woogiu.com/?token=" +
        authenticatedUser!.access_token;
    } catch (error) {
      console.log(error);
    }
  };

  const handleMeetHeroClick = () => {
    hideSidebar(sidebar);
    navigate("/meet-the-heroes");
    sendWoogiEvent("BUTTON_NAV_MEET_HEROES");
  };

  const logOutUser = async () => {
    await hardLogout();
    logout();
    loaded(false);
    setModalController(false);
  };

  return (
    <div
      style={sidebar ? { display: "flex" } : {}}
      className="sidebar-container"
    >
      <div className="logo-nav-container">
        <div className="logo-container">
          <Link onClick={() => hideSidebar(sidebar)} to="/index">
            <img className="logo-img" src={"logo.png"} alt="" />{" "}
          </Link>
        </div>
        <Navmenu />
      </div>
      <div className="sidebar-footer-container cursor-pointer">
        <button
          onClick={handleMeetHeroClick}
          className="meet-heroes-img-container"
          style={{ backgroundImage: "url(images/meethHeroes.jpg)" }}
        />
        {role && role > 0 && (
          <button className="portal-container" onClick={navToData}>
            Parent/Teacher Login
          </button>
        )}
      </div>
      {isIpad && (
        <button onClick={logOutUser} className="logout-link">
          Logout
        </button>
      )}
    </div>
  );
};

export default Sidebar;
