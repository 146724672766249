import React, { useEffect } from "react";
import BlogSlider from "./BlogSlider/BlogSlider";
import HomepageTabs from "./HomepageTabs/HomepageTabs";
import "./Home.css";
import MobileHeader from "../MobileHeader/MobileHeader";
import {
  apiCallerPost,
  imagesBase,
  sendWoogiEvent,
} from "../../../services/api";
import ReadMoreButton from "../ReadMoreButton/ReadMoreButton";
import { useState } from "react";
import { useRef } from "react";
import { useSeasonStore } from "../../../store/season";
import { useUIDataStore } from "../../../store/ui";
import { Circle } from "rc-progress";
import { useAuthStore } from "../../../store/auth";

const Home = () => {
  const season = useSeasonStore((store) => store.season);
  const [gameProgress, setGameProgress] = useUIDataStore((store) => [
    store.gameLoadingProgress,
    store.setGameLoadingProgress,
  ]);
  const [giftItems, setGiftItems] = useState([]);
  const timer = useRef<NodeJS.Timeout>();
  const token = useAuthStore((auth) => auth.userAuth?.access_token);

  // adding for unity load even listener
  useEffect(() => {
    sendWoogiEvent("PAGE_INDEX");

    const listener = (e: any) => {
      if (+e.detail > 10) setGameProgress(+e.detail);
    };

    window.top?.addEventListener("UnityLoadingProgress", listener);
    return () => {
      window.top?.removeEventListener("UnityLoadingProgress", listener);
    };
  }, []);

  useEffect(() => {
    const fetcher = async () => {
      const data = await apiCallerPost({
        cmd: "gms_GmsItem.getGiftList",
        token,
      });
      setGiftItems(data.data.data);
    };

    fetcher();

    if (!timer.current) {
      timer.current = setInterval(() => {
        fetcher();
      }, 10000);
    }

    return () => {
      if (timer.current) clearInterval(timer.current);
    };
  }, []);

  //hide gift box
  const handleHideGiftBox = async () => {
    setGiftItems([]);
    clearInterval(timer.current);
  };

  const onClickPlayNow = () => {
    if (window.top?.sw?.playnow) {
      window.top.sw.playnow();
    }

    sendWoogiEvent("BUTTON_PLAY_NOW");
    clearInterval(timer.current);
  };

  return (
    <React.Fragment>
      <div
        className="home-container"
        style={{ backgroundImage: `url(${imagesBase + "/" + season?.bg_img})` }}
      >
        <MobileHeader />
        <div className="row">
          <div className="season-box-wrap">
            <div className="season-box">
              <h1 className="season-heading">{season?.main_title}</h1>
              <p className="season-description">{season?.main_content}</p>
              <button
                onClick={onClickPlayNow}
                className="btn ml-auto flex flex-row justify-center items-center"
              >
                <Circle
                  trailWidth={20}
                  strokeWidth={20}
                  percent={gameProgress}
                  strokeColor="#04295e"
                  className="w-10 h-6 mr-2 mt-2"
                />
                Play Now
              </button>
            </div>
            <div
              className="gift-wrap"
              style={{ display: giftItems?.length > 0 ? "block" : "none" }}
            >
              <img
                onClick={handleHideGiftBox}
                className="gift"
                src="images/gift.png"
                alt="close"
              />

              <img
                onClick={handleHideGiftBox}
                className="close"
                src="images/close.png"
                alt="close"
              />
              <p>
                You Received a gift in the game!
                <br />
                Login to Check it out!
              </p>
            </div>
          </div>

          <HomepageTabs />
        </div>

        <div className="blog-row">
          <div className="heading-btn-container">
            <h1 className="latest-blogs-heading">Latest Blogs & Updates</h1>
            <ReadMoreButton
              link={"/blog"}
              className="read-more-btntext"
              text="Read More"
            />
          </div>

          {/* Blog Sliders */}
          <BlogSlider />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Home;
