import { Fragment } from "react";
import "./Events.css";
import MobileHeader from "../MobileHeader/MobileHeader";
import PageTitle from "../PageTitle/PageTitle";

import { redirectToGame } from "../../../services/api";
import { useEventStore } from "../../../store/events";
import dayjs from "dayjs";

const Events = () => {
  const events = useEventStore();

  const activeEvent = events?.global;
  const staticEvents = events?.customized;

  const combined = [...activeEvent, ...staticEvents];
  const item = combined.find((i) => +i.featured == 1);

  return (
    <div className="eventsPg-container">
      <MobileHeader />
      <PageTitle title="Events" />
      <div className="eventsData-container">
        <div className="mainEvents-sidebar flex-1">
          {activeEvent?.length > 0 &&
            activeEvent?.map((event) => (
              <div className="events-item">
                <h2 className="event-title">{event.title}</h2>
                <p className="event-descrp">{event.content}</p>

                <div className="date-n-btn">
                  <div>
                    <p className="event-date active">
                      {!!event.start_time && !!event.end_time
                        ? event.start_time + " - " + event.end_time
                        : event.recurrent.split(",")[0]}
                    </p>

                    {event.recurrent.length > 0 && (
                      <p className="event-date active">
                        {!!event.start_time && !!event.end_time
                          ? event.recurrent
                          : event.recurrent.split(",")[1]}{" "}
                        EST
                      </p>
                    )}
                  </div>
                  <button
                    disabled={!event.active}
                    className="relative btntxt"
                    onClick={
                      !!event.active
                        ? redirectToGame.bind(null, event.teleport_scene)
                        : () => {}
                    }
                  >
                    <img
                      className="btn-img"
                      src={
                        !!event.active
                          ? "images/Portal_btnGreen.png"
                          : "images/Portal_btnOrange.png"
                      }
                      alt=""
                    />

                    <span className="absolute top-0 w-full left-0">
                      {!!event.active ? "Go Now" : "Coming Up"}
                    </span>
                  </button>
                </div>
              </div>
            ))}

          {!activeEvent?.length && <p className="not-found">No Event Found!</p>}
        </div>

        {item && (
          <div className="flex-1 relative">
            <div className="fixedElement">
              {" "}
              <div className="events-item">
                <h2 className="event-title">{item.title}</h2>
                <p className="event-descrp">{item.content}</p>

                <div className="date-n-btn">
                  <div>
                    <p className="event-date active">
                      {!!item.start_time && !!item.end_time
                        ? item.start_time + " - " + item.end_time
                        : item.recurrent.split(",")[0]}
                    </p>

                    {item.recurrent.length > 0 && (
                      <p className="event-date active">
                        {!!item.start_time && !!item.end_time
                          ? item.recurrent
                          : item.recurrent.split(",")[1]}{" "}
                        EST
                      </p>
                    )}
                  </div>
                  <button
                    disabled={!item.active}
                    className="relative btntxt"
                    onClick={
                      !!item.active
                        ? redirectToGame.bind(null, item.teleport_scene)
                        : () => {}
                    }
                  >
                    <img
                      className="btn-img"
                      src={
                        !!item.active
                          ? "images/Portal_btnGreen.png"
                          : "images/Portal_btnOrange.png"
                      }
                      alt=""
                    />

                    <span className="absolute top-0 w-full left-0">
                      {!!item.active ? "Go Now" : "Coming Up"}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="schoolEvents-sidebar flex-1">
          {staticEvents?.length > 0 &&
            staticEvents?.map((event) => (
              <Fragment>
                <div className="school-event-box">
                  <img
                    className="schoolEvent-img"
                    src="images/mobile-header-bg.png"
                    alt=""
                  />
                  <div className="school-event-content">
                    <h3 className="schl-even-title">{event.title}</h3>
                    <p className="scl-event-descrp">{event.content}</p>
                    <div className="scl-event-dateContnr">
                      <span className="schl-event-date">
                        {dayjs(event.start_time).format("dddd")}
                      </span>
                      <span className="schl-event-date">
                        {dayjs(event.start_time).format("MMM - DD - YYYY")}
                      </span>
                      <span className="schl-event-date">
                        {dayjs(event.start_time).format("h:mm:ss a")}
                      </span>
                    </div>
                  </div>
                </div>
              </Fragment>
            ))}
          {!staticEvents?.length && (
            <p className="not-found">No Event Found!</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Events;
