import { create } from "zustand";
import { Game } from "../lib/types";

interface GamesStore {
  games: Game[];
  loadGames: (comics: Game[]) => void;
  unloadGames: () => void;
}

export const useGamesStore = create<GamesStore>((set) => ({
  games: [],
  loadGames: (games: Game[]) => set(() => ({ games })),
  unloadGames: () => set(() => ({ games: [] })),
}));
