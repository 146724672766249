import { useContext, useState } from "react";
import "./HomepageTabs.css";

import MyHero from "./MyHero/MyHero";
import { createPortal } from "react-dom";
import NewModal from "./FeedbackModal/NewModal";

import WhatsNew from "../WhatsNew/WhatsNew";
import { AnimatePresence } from "framer-motion";

import { hardLogout } from "../../../../lib";
import { useAuthStore } from "../../../../store/auth";
import { useUIDataStore } from "../../../../store/ui";
import CacheClear from "./CacheClear";
import { GlobalInfo } from "../../../Shared/Modals";
import LoginCredentialsModal from "../../../Shared/LoginCredentialsModal";

const HomepageTabs = () => {
  const [selectBox, setSelectBox] = useState(1);
  const [feedbackModal, setFeedbackModal] = useState(false);
  const loaded = useUIDataStore((store) => store.setLoader);
  const { setModalController } = useContext(GlobalInfo);
  const [showCredsModal, setShowCredsModal] = useState(false);

  const logout = useAuthStore((store) => store.logout);

  const logOutUser = async () => {
    await hardLogout();

    logout();
    loaded(false);
    setModalController(false);
  };

  return (
    <AnimatePresence>
      <div className="user-wrap ">
        <div className="flex flex-row items-start pl-2 w-full">
          <img
            src="/images/HeoricSkills.png"
            className="w-32 cursor-pointer mr-6"
            alt="hero skill btn"
            onClick={() => (window as any).showSkillRadar(true)}
          />
          <div className="character-container">
            <MyHero MyHeroTabActive={selectBox} />

            <div className="flex flex-col mb-3 justify-between pr-2">
              <div className="flex flex-row flex-1 mb-0 items-start w-full justify-between">
                <div className="mb-0">
                  <button
                    className="feedback-btn"
                    onClick={() => setFeedbackModal((old) => !old)}
                  >
                    Give Feedback
                  </button>
                  <button
                    onClick={() => setShowCredsModal(true)}
                    className="ml-2 capitalize text-xl"
                  >
                    Login Credentials
                  </button>
                </div>

                <button className="logout-link" onClick={logOutUser}>
                  Logout
                </button>
              </div>

              <CacheClear />
            </div>
            {feedbackModal &&
              createPortal(
                <NewModal dismiss={() => setFeedbackModal(false)} />,
                document.body
              )}
          </div>
        </div>
        <WhatsNew />
      </div>
      {showCredsModal && (
        <LoginCredentialsModal close={() => setShowCredsModal(false)} />
      )}
    </AnimatePresence>
  );
};

export default HomepageTabs;
