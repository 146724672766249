import { create } from "zustand";

interface VideosStore {
  videos: any[];
  loadVideos: (videos: any[]) => void;
  unloadVideos: () => void;
}

export const useVideosStore = create<VideosStore>((set) => ({
  videos: [],
  loadVideos: (videos: any[]) => set(() => ({ videos })),
  unloadVideos: () => set(() => ({ videos: [] })),
}));
