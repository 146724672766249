type Props = {};
export default function Footer({}: Props) {
  return (
    <div className="absolute bottom-0 w-full flex items-center justify-center ">
      <img
        onClick={() => window.open("https://hellou.heroesleague.us/", "_blank")}
        src={"/helloU-web.png"}
        alt="HelloU Logo"
        className="footer-logo web"
      />
      <img
        onClick={() => window.open("https://hellou.heroesleague.us/", "_blank")}
        src={"/helloU-mobile.png"}
        alt="HelloU Logo"
        className="footer-logo mobile"
      />
    </div>
  );
}
