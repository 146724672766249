import { useMemo } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import * as yup from "yup";

import GeneralButton from "heroic-ui-library/dist/components/Button";
import Input from "heroic-ui-library/dist/components/Input";
import { isDev, top } from "../../../lib";
import { apiCallerPostLogin } from "../../../services/api";
import omit from "lodash/omit";
import { useAuthStore } from "../../../store/auth";
import { useNavigate } from "react-router-dom";

type Props = { fetchData: any; error: any; setError: any };

export default function LoginForm({ error, setError, fetchData }: Props) {
  const login = useAuthStore((store) => store.login);
  const navigate = useNavigate();

  const init = useMemo(
    () => ({
      username: "",
      password: "",
    }),
    []
  );

  const validation = yup.object({
    username: yup.string().required().label("Username"),
    password: yup.string().required().label("Password"),
  });

  const handleSubmit = async (
    { username, password }: typeof init,
    { setSubmitting }: FormikHelpers<typeof init>
  ) => {
    setSubmitting(true);
    try {
      const formData = {
        cmd: "WoogiOAuth.signInMod",
        password: password.trim(),
        name: username.trim(),
      };

      let result = await apiCallerPostLogin(formData);

      if (result.data.code === 200) {
        const data = result.data;

        if (isDev) {
          login({
            access_token: data.access_token,
            user: data.jwt_info.user,
            jwt_str: data.jwt_str,
          });
          localStorage.setItem(
            "access_token",
            JSON.stringify(data.access_token)
          );
          localStorage.setItem("jwt_str", JSON.stringify(data.jwt_str));
          const omitted = omit(
            {
              uid: data.jwt_info.user.uid,
              id: data.jwt_info.user.id,
              login_name: data.jwt_info.user.login_name,
              nickname: data.jwt_info.user.nickname,
              ...data.jwt_info.user,
            },
            ["password"]
          );

          localStorage.setItem("user", JSON.stringify(omitted));

          await fetchData({
            access_token: data.access_token,
            user: data.jwt_info.user,
            jwt_str: data.jwt_str,
          });

          navigate("/index");
        }

        console.log("login to game now");
        top?.sw && top?.sw?.loginSucceed && top?.sw?.loginSucceed();
      }

      if (result.data.code !== 200) {
        setError(result.data.msg);
      }
    } catch (error: any) {
      setError(error.message);
    }

    setSubmitting(false);
  };

  return (
    <Formik
      validationSchema={validation}
      initialValues={init}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleSubmit,
        values,
        handleBlur,
        handleChange,
        isSubmitting,
      }) => {
        return (
          <div className="login-form m-auto">
            <Form onKeyDown={(e) => e.key == "Enter" && handleSubmit()}>
              <div className=" form-field">
                <Input
                  value={values.username}
                  type="text"
                  name="username"
                  placeholder="Username"
                  onBlur={(e) => handleBlur(e)}
                  onChange={(e) => {
                    handleChange(e);
                    setError("");
                  }}
                />
                {errors.username && (
                  <p className="text-red-600 error acme ">{errors.username}</p>
                )}
              </div>

              <div className="form-field">
                <Input
                  onBlur={(e) => handleBlur(e)}
                  onChange={(e) => {
                    handleChange(e);
                    setError("");
                  }}
                  value={values.password}
                  type="password"
                  name="password"
                  placeholder="Password"
                />

                {errors.password && (
                  <p className="text-red-600 error acme ">{errors.password}</p>
                )}
              </div>
              {error && (
                <p className="text-red-600 error acme text-3xl mt-0 mb-3 ">
                  {error}
                </p>
              )}
              <div className="mb-2 text-center flex justify-between items-center flex-col">
                {!isSubmitting && (
                  <GeneralButton
                    btnType="green"
                    label="Login"
                    onClick={() => handleSubmit()}
                    className="submit-btn"
                  />
                )}
                {isSubmitting && <div className="loader" />}
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
