import { useState } from "react";
import "./News.css";
import MobileHeader from "../MobileHeader/MobileHeader";
import PageTitle from "../PageTitle/PageTitle";

import Carousel from "react-elastic-carousel";
import { useRef } from "react";
import useIpad from "../../Shared/useIpad";
import { useNewsStore } from "../../../store/news";

const News = () => {
  const news = useNewsStore((store) => store.news);
  const [setselectedNews, setSetselectedNews] = useState(news[0]);
  const ref = useRef();
  const { isIpad } = useIpad();

  return (
    <div className="newsPage-container">
      <MobileHeader />
      <PageTitle title="News" />
      <div className="newspg-main">
        <div className="newspg-content-container">
          <div className="newspg-main-imgcontainer">
            <img
              className="newspg-main-img"
              src={setselectedNews.attachment[0]}
              alt="news main thumbnail"
            />
          </div>
          <div className="newspg-description-container">
            <h1 className="blogListing-title">{setselectedNews.title}</h1>
            <p className="newspg-dscrp">{setselectedNews.content}</p>
          </div>
        </div>
        {!isIpad && (
          <div className="moreNews-sidebar-container">
            <img
              className="moreNews-sidebar-img"
              src="images/moreNewsSidebar.png"
              alt=""
            />

            <div className="moreNews-listContainer">
              <h3 className="morenews-title">More</h3>
              <div className="moreNewsitemContainer text-center flex-col ">
                <button
                  onClick={() => ref.current.slidePrev()}
                  className="slide-btn absolute rotate-90 z-10 -top-5 -ml-6 "
                  style={{
                    background: "url(images/icon-back.png)",
                  }}
                />
                <Carousel
                  ref={ref}
                  disableArrowsOnEnd
                  pagination={false}
                  showArrows={false}
                  verticalMode={!isIpad}
                  itemsToShow={3}
                >
                  {news.map((latestNews, index) => (
                    <img
                      key={latestNews.id}
                      onClick={() => setSetselectedNews(latestNews)}
                      className="moreNewsImg cursor-pointer"
                      src={latestNews.attachment[0]}
                      alt={latestNews.title}
                    />
                  ))}
                </Carousel>

                <button
                  onClick={() => ref.current.slideNext()}
                  className="slide-btn absolute -rotate-90 -mt-8 -ml-5"
                  style={{
                    background: "url(images/icon-back.png)",
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {isIpad && (
          <div className="slider-container-mobile">
            <button
              onClick={() => ref.current.slidePrev()}
              className="slide-btn absolute z-10 left-0"
              style={{
                background: "url(images/icon-back.png)",
              }}
            />
            <Carousel
              showArrows={false}
              ref={ref}
              pagination={false}
              itemPadding={[10]}
              itemsToShow={3}
            >
              {news.map((latestNews) => (
                <img
                  key={latestNews.id}
                  onClick={() => setSetselectedNews(latestNews)}
                  className="moreNewsImg cursor-pointer"
                  src={latestNews.attachment[0]}
                  alt={latestNews.title}
                />
              ))}
            </Carousel>
            <button
              onClick={() => ref.current.slideNext()}
              className="slide-btn absolute -rotate-180 right-0"
              style={{
                background: "url(images/icon-back.png)",
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default News;
