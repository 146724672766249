import { create } from "zustand";
import { User, UserAuth } from "../lib/types";

interface AuthStore {
  userAuth: UserAuth | null;
  login: (userAuth: UserAuth) => void;
  logout: () => void;
  updateUser: (user: User) => void;
}

export const useAuthStore = create<AuthStore>((set) => ({
  userAuth: {} as UserAuth,
  login: (userAuth: UserAuth) => set(() => ({ userAuth })),
  logout: () => set(() => ({ userAuth: null })),
  updateUser: (user: User) =>
    set((store) => ({
      userAuth: {
        access_token: store.userAuth?.access_token || "",
        jwt_str: store.userAuth?.jwt_str || "",
        user: { ...store.userAuth?.user, ...user },
      },
    })),
}));
