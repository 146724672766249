import React from "react";

export default function CommentForm({
  btnClasses = "",
  value,
  onChange,
  loading,
  onSubmit,
}) {
  return (
    <div className="commentForm">
      <textarea
        placeholder="Write a comment..."
        value={value}
        onChange={(e) => onChange(e.target.value)}
        cols="320"
        rows="7"
        className="commentfield rounded  border-black bg-gray-100"
      />
      <button
        disabled={loading}
        onClick={onSubmit}
        className={"addComment-btn " + btnClasses}
      >
        {loading ? "Loading..." : "Add Comment"}
      </button>
    </div>
  );
}
