import { create } from "zustand";
import { EventData, EventItem } from "../lib/types";

// Todo: Event Type

interface EventStore {
  customized: EventItem[];
  global: EventItem[];
  loadEvents: (events: EventData) => void;
  unloadEvents: () => void;
}

export const useEventStore = create<EventStore>((set) => ({
  customized: [],
  global: [],
  loadEvents: (events: EventData) => set(() => ({ ...events })),
  unloadEvents: () => set(() => ({ customized: [], global: [] })),
}));
