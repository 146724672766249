import { create } from "zustand";

interface LoaderStore {
  loader: boolean;
  passwordModal: number;
  skillRadar: number;
  gameLoadingProgress: number;
  setLoader: (val: boolean) => void;
  loadUiData: (data: Partial<LoaderStore>) => void;
  setGameLoadingProgress: (gameLoadingProgress: number) => void;
}

export const useUIDataStore = create<LoaderStore>((set) => ({
  loader: false,
  passwordModal: 0,
  skillRadar: 0,
  gameLoadingProgress: 10,
  setGameLoadingProgress: (gameLoadingProgress: number) =>
    set(() => ({ gameLoadingProgress })),
  setLoader: (loader: boolean) => set(() => ({ loader })),
  loadUiData: (data: Partial<LoaderStore>) =>
    set((store) => ({ ...store, ...data })),
}));
