import { create } from "zustand";
import { Blog } from "../lib/types";

interface BlogStore {
  blog: Blog[];
  loadBlog: (blogs: Blog[]) => void;
  unloadBlog: () => void;
}

export const useBlogStore = create<BlogStore>((set) => ({
  blog: [],
  loadBlog: (blogs: Blog[]) => set(() => ({ blog: blogs })),
  unloadBlog: () => set(() => ({ blog: [] })),
}));
