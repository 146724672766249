import axios from "axios";
import { isDev } from "../lib";

export const API_URL = isDev
  ? "https://portal.americau.com/woogi/0.1/actor/cgi.php"
  : "/woogi/0.1/actor/cgi.php";

export const apiCallerGet = (data: any) => axios.get(API_URL, data);

const tokenData = localStorage.getItem("access_token");
export const token = tokenData && tokenData !== 'undefined' ? JSON.parse(tokenData) : null;

export const apiCallerPost = (data: any) => {
  return axios.post(
    API_URL,
    { ...data }
    // {
    //   headers: { jwt },
    // }
  );
};

export const apiCallerPostLogin = (data: any) => {
  return axios.post(API_URL, { ...data });
};

export function formatElapsedTime(add_time: number | string) {
  const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
  const elapsedSeconds = currentTime - +add_time;

  if (elapsedSeconds < 60) {
    return `Posted ${elapsedSeconds} seconds ago.`;
  } else if (elapsedSeconds < 3600) {
    const elapsedMinutes = Math.floor(elapsedSeconds / 60);
    const remainingSeconds = elapsedSeconds % 60;
    return `Posted ${elapsedMinutes} minute${
      elapsedMinutes > 1 ? "s" : ""
    } and ${remainingSeconds} second${remainingSeconds > 1 ? "s" : ""} ago.`;
  } else if (elapsedSeconds < 86400) {
    const elapsedHours = Math.floor(elapsedSeconds / 3600);
    const remainingMinutes = Math.floor((elapsedSeconds % 3600) / 60);

    return `Posted ${elapsedHours} hour${
      elapsedHours > 1 ? "s" : ""
    } and ${remainingMinutes} minute${remainingMinutes > 1 ? "s" : ""} ago.`;
  } else {
    const elapsedDays = Math.floor(elapsedSeconds / 86400);
    return `Posted ${elapsedDays} day${elapsedDays > 1 ? "s" : ""} ago.`;
  }
}

export function redirectToGame(event: string) {
  window.location.href = "/loading?scene=" + event; //relative to domain
}

export const imagesBase =
  process.env.NODE_ENV == "production"
    ? window.location.origin
    : "https://portal.americau.com";

export const parseItemImage = (url: string) => {
  if (!isDev) return url;

  return url
    ? url.includes("mainbackend")
      ? url
      : "https://portal.americau.com/" + url
    : "";
};

let portalLoginStatus = false;

export const sendWoogiEvent = async (eventName: string) => {
  if (!portalLoginStatus) {
    const formData = {
      cmd: "WoogiOAuth.portalLoginStatus",
    };

    let result = await apiCallerPostLogin(formData);

    if (result.data == 200) {
      portalLoginStatus = true;
    }
  }
  const windowNew = window as any;

  if (typeof windowNew?.woogievent == "function" && portalLoginStatus) {
    windowNew?.woogievent(eventName);
  }
};
