import "./SeasonPassStories.css";
import MobileHeader from "../../MobileHeader/MobileHeader";
import PageTitle from "../../PageTitle/PageTitle";

import { useContext } from "react";
import { GlobalInfo } from "../../../Shared/Modals";
import { StoryElement } from "../../../../lib/types";
import { useStoryStore } from "../../../../store/story";

const SeasonPassStories = () => {
  const { setSeasonPass } = useContext(GlobalInfo);
  const story = useStoryStore((store) => store.story);

  return (
    <div className="seasonPs-container">
      <MobileHeader />
      <PageTitle customColor="#FF8500" title="Stories" />
      <div className="blogCard-container">
        {story.stories.map((item: StoryElement) => {
          return (
            <div
              onClick={() => setSeasonPass(item)}
              className="seasonPassCard "
            >
              <div className="seasonPass-img-container">
                <img
                  className="seasonStories-thumbnail"
                  src={item.theme_img}
                  alt={item.title}
                />
              </div>
              <div className="seasonPassContent-container ">
                <span className="blogtitle-link">
                  <h1 className="blogListing-title">{item.title}</h1>
                </span>
                <p className="blog-date">
                  {new Date(+item.c_time * 1000).toLocaleDateString("en-US", {
                    weekday: "short",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}{" "}
                </p>
                <p className="blog-description">{item?.content}</p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="blog-pagintaion">
        <img
          className="blog-left-arrow"
          src="../images/blog-left-arrow.png"
          alt=""
        />
        <img
          className="blog-right-arrow"
          src="../images/blog-right-arrow.png"
          alt=""
        />
      </div>
    </div>
  );
};

export default SeasonPassStories;
