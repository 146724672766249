import { create } from "zustand";
import { Announcement } from "../lib/types";

interface AnoucementStore {
  announcements: Announcement[];
  news: string[];
  readAnoucement: (id: string) => void;
  loadAnnouncements: (data: {
    announcement: Announcement[];
    news: string[];
  }) => void;
}

export const useAnouncementsStore = create<AnoucementStore>((set) => ({
  announcements: [],
  news: [],
  readAnoucement: (id: string) =>
    set((store) => {
      return {
        announcements: store.announcements.map((item) =>
          item.id == id ? { ...item, unread: 0 } : item
        ),
      };
    }),
  loadAnnouncements: ({
    announcement,
    news,
  }: {
    announcement: Announcement[];
    news: string[];
  }) => set(() => ({ announcements: announcement, news })),
}));
