import React from "react";
import "./SeasonPass.css";
import PageTitle from "../PageTitle/PageTitle";
import MobileHeader from "../MobileHeader/MobileHeader";

import ReadMoreButton from "../ReadMoreButton/ReadMoreButton";
import { useContext } from "react";
import { GlobalInfo } from "../../Shared/Modals";
import { useStoryStore } from "../../../store/story";

const SeasonPass = () => {
  const { setSeasonPass } = useContext(GlobalInfo);
  const story = useStoryStore((store) => store.story);

  return (
    <div className="seasonPs-container">
      <MobileHeader />
      <PageTitle customColor="#FF8500" title="Story" />
      <div className="seasonPs-content-container">
        <div className="seasonPs-sidebar">
          <h1 className="season-title">{story.sub_title}</h1>
          <div className="seasonPs-desc-container">
            <p className="seasonPs-descrp">{story.sub_content}</p>
          </div>
        </div>
        {story.stories.length > 1 && (
          <div className="seasonPs-main">
            <h2 className="seasonPs-newTag">New</h2>
            <div className="first-slide">
              <button
                onClick={() => setSeasonPass(story.stories[0])}
                className="activeSeasonPs-data"
              >
                <h2 className="active-seasonPs-title">
                  {story.stories[0].title}
                </h2>
                <span className="seasonPs-date">
                  {new Date(story.stories[0].c_time * 1000).toLocaleDateString(
                    "en-US",
                    {
                      weekday: "short",
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    }
                  )}
                </span>
              </button>
              <img
                onClick={() => setSeasonPass(story.stories[0])}
                src={story.stories[0].theme_img}
                alt={story.stories[0].title}
                className="seasonPs-main-img"
              />
            </div>
            <React.Fragment>
              <div className="latestSeasonStories-container">
                {story.stories.slice(1).map((item) => (
                  <div className="seasonBox1">
                    <img
                      onClick={() => setSeasonPass(item)}
                      className="seasonItme-img"
                      src={item.theme_img}
                      alt={item.title}
                    />
                    <span
                      onClick={() => setSeasonPass(item)}
                      className="latestSeasonPs-data"
                    >
                      <h2 className="seasonPs-item-title">{item.title}</h2>
                      <span className="seasonPs-item-date">
                        {new Date(item.c_time * 1000).toLocaleDateString(
                          "en-US",
                          {
                            weekday: "short",
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          }
                        )}
                      </span>
                    </span>
                  </div>
                ))}
              </div>
              <ReadMoreButton
                link={"seasonpass-list"}
                text={"Read More"}
                className="read-more-btntext"
                parentClass="readMore-seasonStories-btn"
              />
            </React.Fragment>
          </div>
        )}

        {story.stories.length == 1 && (
          <div className="text-6xl text-blue-900 w-full text-center flex justify-center items-center">
            We will be adding more stories soon
          </div>
        )}
      </div>
    </div>
  );
};

export default SeasonPass;
