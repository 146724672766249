import { useState } from "react";

function SkillRadarPopup({ onDismiss, skillRadarPortal }) {
  const [loading, setLoading] = useState(true);

  return (
    <div className="flex justify-center items-center w-full absolute top-0 z-50 bg-blue-900/80 h-full">
      <button
        className=" transition-all w-14 h-14 bg-cover absolute top-3 right-4 z-50 hover:w-16 hover:h-16"
        onClick={onDismiss}
        style={{
          background: "url(images/close.png)",
          backgroundSize: "cover",
        }}
      />
      {loading && (
        <div className="flex justify-center items-center absolute top-0 w-full h-full z-40">
          <div className="loading-spinner z-50"></div>
        </div>
      )}
      <iframe
        className="w-screen h-screen m-auto"
        onLoad={() => setLoading(false)}
        title="Skill Radar"
        name="skill-radar"
        src={`/skill_radar?token=${skillRadarPortal.access_token}`}
      />
    </div>
  );
}

export default SkillRadarPopup;
