import { create } from "zustand";
import { Season } from "../lib/types";

interface SeasonStore {
  season: Season;
  loadSeason: (season: Season) => void;
  unloadSeason: () => void;
}

export const useSeasonStore = create<SeasonStore>((set) => ({
  season: {} as Season,
  loadSeason: (season: Season) => set(() => ({ season })),
  unloadSeason: () => set(() => ({ season: {} as Season })),
}));
