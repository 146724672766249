import "./NewsComponent.css";
import { apiCallerPost, parseItemImage } from "../../../../services/api";
import { useAnouncementsStore } from "../../../../store/Anoucements";
import { useRef, useState } from "react";
import { Announcement } from "../../../../lib/types";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper/types";
import iconBack from "/images/icon-back.png";
import iconNext from "/images/icon-next.png";
import newsBackIcon from "/images/HL_UI-Arrowsticker.png";
import dayjs from "dayjs";
import classNames from "classnames";
import { useAuthStore } from "../../../../store/auth";

type Props = {};

export default function NewsComponent({}: Props) {
  const { announcements, news } = useAnouncementsStore((store) => ({
    news: store.news,
    announcements: store.announcements,
  }));
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const token = useAuthStore((auth) => auth.userAuth?.access_token);

  const readAnoucement = useAnouncementsStore((store) => store.readAnoucement);
  const swiperRef = useRef<SwiperType | null>(null);

  const onClickAnoucement = (anoucement: Announcement, index: number) => {
    apiCallerPost({
      cmd: "auth_HeroLeaguePortalIndex.readAnnouncement",
      target_id: anoucement.id,
      token,
    });

    readAnoucement(anoucement.id);
    setSelectedIndex(index);
  };

  const selectedAnc = announcements?.[selectedIndex];

  return (
    <div className="frame-data-container">
      <div className="anou-container">
        <div className="anou-heading">Announcements</div>
        <ul className="list-none">
          {announcements.map((an, index) => (
            <li
              key={an.id}
              onClick={() => onClickAnoucement(an, index)}
              className={classNames(
                "anoucement-item pb-5 hover:cursor-pointer",
                {
                  unread: +an.unread == 1,
                  read: +an.unread == 0,
                }
              )}
            >
              {an.title}
            </li>
          ))}
        </ul>
      </div>
      <div className="anou-content bg-nav-blue">
        {selectedAnc && (
          <div>
            <h1 className="anoucement-title acme">{selectedAnc.title}</h1>
            <h2 className="anoucement-date">
              {dayjs(selectedAnc.c_time * 1000).format("ddd MMM YYYY")}
            </h2>
            <div className="images">
              {selectedAnc.images.map((img) => (
                <img
                  className="content-images"
                  src={parseItemImage(img)}
                  alt={selectedAnc.title}
                />
              ))}
            </div>
            <p className="anoucement-content">{selectedAnc.content}</p>
          </div>
        )}
        {!selectedAnc && (
          <div className="w-full ">
            <div className="title-heading acme">News</div>
            <Swiper
              onSwiper={(swiper) => (swiperRef.current = swiper)}
              nested
              slidesPerView={1}
            >
              {news.map((newSlide) => (
                <SwiperSlide key={newSlide}>
                  <img alt="news" src={parseItemImage(newSlide)} />
                </SwiperSlide>
              ))}
            </Swiper>

            <div className="slider-btns-cont">
              <button
                className="next-prev-btns mr-10"
                style={{ backgroundImage: "url(" + iconBack + ")" }}
                onClick={() => swiperRef.current?.slidePrev()}
              />

              <button
                className="next-prev-btns ml-10 "
                style={{ backgroundImage: "url(" + iconNext + ")" }}
                onClick={() => swiperRef.current?.slideNext()}
              />
            </div>
          </div>
        )}
      </div>

      {selectedAnc && (
        <button
          onClick={() => setSelectedIndex(-1)}
          className="back-button-mobile"
        >
          <img src={newsBackIcon} alt="news" />
          <span className=" text-white shadow-sm">Back to News</span>
        </button>
      )}
    </div>
  );
}
