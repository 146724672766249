import { create } from "zustand";
import { News } from "../lib/types";

interface BlogStore {
  news: News[];
  loadNews: (news: News[]) => void;
  unloadNews: () => void;
}

export const useWhatsNewsStore = create<BlogStore>((set) => ({
  news: [],
  loadNews: (news: News[]) => set(() => ({ news })),
  unloadNews: () => set(() => ({ news: [] })),
}));
