import "./Games.css";
import MobileHeader from "../MobileHeader/MobileHeader";
import PageTitle from "../PageTitle/PageTitle";

import {
  API_URL,
  parseItemImage,
  sendWoogiEvent,
  token,
} from "../../../services/api";
import { useEffect, useState } from "react";
import Carousel from "react-elastic-carousel";
import className from "classnames";
import useIpad from "../../Shared/useIpad";
import { useGamesStore } from "../../../store/games";
import axios from "axios";
import { Game } from "../../../lib/types";
import { top } from "../../../lib";

function MyArrow({ type, onClick, isEdge }: any) {
  return (
    <button
      disabled={isEdge}
      onClick={onClick}
      className={className("slide-btn self-center", {
        "-rotate-180": type == "NEXT",
      })}
      style={{
        background: "url(images/icon-back.png)",
      }}
    />
  );
}

const Games = () => {
  const games = useGamesStore((store) => store.games);
  const { isIpad } = useIpad();
  const [startedGame, setStartedGame] = useState<{
    game_name: string;
    game_id: string;
  } | null>(null);

  const openGame = (game: Game) => {
    axios
      .post(API_URL, {
        cmd: "gms_PortalGameHttp.applyGame",
        game_id: game.game_id,
        token: token,
      })
      .then((res) => {
        if (res.data.status > 0) {
          //fail to apply game, stop here
          return;
        }

        //open game url in current tab/window
        window.location.href = game.play_url;
      });
  };

  useEffect(() => {
    sendWoogiEvent("BUTTON_OPEN_MINI_GAME");
  }, []);

  useEffect(() => {
    top.addEventListener("onwsopen", startListenSocketMsg);

    function startListenSocketMsg() {
      top.removeEventListener("onwsopen", startListenSocketMsg);

      top.ws.send({
        cmd: "wc_minigame_PortalGame.subscribeByToken",
        token,
      });

      top.ws.CmdEventTarget.addEventListener("PortalGame.readyGame", (e) => {
        //todo: show mini game notification

        setStartedGame(e.detail);
      });
    }
  }, []);

  return (
    <div className="gamesPg-container">
      <MobileHeader />
      <PageTitle title="Games" />
      <div className="gamesContentContainer">
        <div className="gamesDataContainer">
          <Carousel
            verticalMode={isIpad}
            renderArrow={MyArrow}
            itemsToShow={isIpad ? 2 : 3}
            pagination={false}
          >
            {games.map((game) => {
              return (
                <div
                  key={game.id}
                  className="gameIcon"
                  onClick={() => openGame(game)}
                >
                  <img src={parseItemImage(game.img)} alt="games race icon" />
                  <h2 className="gameTitle">{game.name}</h2>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Games;
