import React, {
  PropsWithChildren,
  createContext,
  useEffect,
  useState,
} from "react";
import ComicsModal from "./ComicsModal/ComicsModal";
import SkillRadarPopup from "./SkillRadar";
import SeasonPassModal from "../Main/SeasonPass/SeasonPassModal";
import AssetsSlider from "./Slider/Slider";
import SkillRadarMain from "../Main/SkillRadar/SkillRadarMain";
import { useAuthStore } from "../../store/auth";
import { useUIDataStore } from "../../store/ui";

type ContextType = {
  sidebar: boolean;
  hideSidebar: (enableSidebar: boolean) => void;
  setComicsPortal: any;
  setSkillRadarPortal: any;
  setSeasonPass: any;
  setModalController: (x: boolean) => void;
};

export const GlobalInfo = createContext<ContextType>({} as ContextType);

function Modals({ children }: PropsWithChildren) {
  const [sidebar, setEnableSidebar] = useState(false);
  const [comicsPortal, setComicsPortal] = useState<any>();
  const [skillRadarPortal, setSkillRadarPortal] = useState<any>();
  const [seasonPass, setSeasonPass] = useState<any>();
  const [showSliderModal, setShowSliderModal] = useState(true);
  const [skillRadar, setSkillRadar] = useState(false);
  const auth = useAuthStore((store) => store.userAuth);
  const loader = useUIDataStore((store) => store.loader);

  //this helps hiding the modals when user logs in again.
  //once user log in again he shouldn't get the modals again.
  // on page refresh, this will be initialy true.
  const [modalController, setModalController] = useState(true);

  const isLoggedIn = !!auth?.access_token;

  (window as any).showSkillRadar = (show: boolean) => {
    setSkillRadar(show);
  };

  const hideSidebar = (enableSidebar: boolean) => {
    setEnableSidebar(!enableSidebar);
  };

  useEffect(() => {
    const key = localStorage.getItem("disable_sliders");

    if (key && key == "1") {
      setShowSliderModal(false);
      localStorage.removeItem("disable_sliders");
    }
  }, []);

  return (
    <GlobalInfo.Provider
      value={{
        setComicsPortal,
        sidebar,
        hideSidebar,
        setSkillRadarPortal,
        setSeasonPass,
        setModalController,
      }}
    >
      {children}
      {isLoggedIn && loader && modalController && (
        <React.Fragment>
          {comicsPortal && (
            <ComicsModal onHide={setComicsPortal} portal={comicsPortal} />
          )}

          {skillRadarPortal && (
            <SkillRadarPopup
              skillRadarPortal={skillRadarPortal}
              onDismiss={() => setSkillRadarPortal(null)}
            />
          )}

          {seasonPass && (
            <SeasonPassModal
              item={setSeasonPass}
              dismiss={() => setSeasonPass(null)}
            />
          )}

          {skillRadar && <SkillRadarMain />}
          {showSliderModal && (
            <AssetsSlider
              show={showSliderModal}
              onShow={(x) => setShowSliderModal(x)}
            />
          )}
        </React.Fragment>
      )}
    </GlobalInfo.Provider>
  );
}

export default Modals;
