import { useEffect } from "react";
import { useState } from "react";

const useIpad = () => {
  const [isIpad, setIsIpad] = useState(false);

  useEffect(() => {
    const width = window.innerWidth;

    if (width <= 1024) {
      setIsIpad(true);
    }
  }, []);
  return { isIpad };
};

export default useIpad;
