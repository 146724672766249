import React from "react";
import { Link } from "react-router-dom";
import "./ReadMoreButton.css";

const ReadMoreButton = ({ link, className, text, parentClass }) => {
  return (
    <>
      <div
        className={`read-more-btn-container  ${parentClass ? parentClass : ""}`}
      >
        <Link to={link} className={className}>
          {text}
        </Link>
      </div>
    </>
  );
};

export default ReadMoreButton;
