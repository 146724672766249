import "./MyHero.css";
import { parseItemImage } from "../../../../../services/api";
import { useAuthStore } from "../../../../../store/auth";

const MyHero = ({ MyHeroTabActive }: any) => {
  const auth = useAuthStore((store) => store.userAuth);

  return (
    <div
      style={
        MyHeroTabActive === 1
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }
          : { display: "none" }
      }
      className=""
    >
      <div className="dailyChalleng-box">
        {auth?.user?.avatar && (
          <img
            className="w-12 mr-2"
            src={parseItemImage(auth.user?.avatar)}
            alt={auth?.user?.nickname}
          />
        )}
        <p className="charcter-name">{auth?.user?.nickname}</p>
      </div>
    </div>
  );
};

export default MyHero;
