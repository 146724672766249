type Props = {};

export default function SkillRadarMain({}: Props) {
  return (
    <iframe
      src="/client/gmsui/skill-radar-chart/dist/index.html?autoClose=2&standAlone=1"
      className="absolute top-0 left-0 w-screen h-screen z-50"
      name="skill-radar"
      title="skill-radar"
    />
  );
}
