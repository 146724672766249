import React from "react";
import "./BlogSlider.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { imagesBase } from "../../../../services/api";
import { formateDate } from "../../../Shared/dateformater";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import { Navigation } from "swiper/modules";
import "swiper/css/navigation";
import { useRef } from "react";
import { getThumbail } from "../../../../lib";
import { useBlogStore } from "../../../../store/blog";

const BlogSlider = () => {
  const sliderRef = useRef(null);
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  // Fetching data
  const blogs = useBlogStore((store) => store.blog);

  const sortedBlogs = blogs
    ? blogs?.sort(function (a, b) {
        return new Date(b.c_time).getTime() - new Date(a.c_time).getTime();
      })
    : [];

  return (
    <>
      <div className="blog-container relative">
        <Swiper
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            1365: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
          }}
          ref={sliderRef}
          mousewheel={true}
          keyboard={true}
          modules={[Navigation]}
          className="mySwiper"
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
          }}
        >
          <img
            className="swiper-button-prev"
            ref={navigationPrevRef}
            onClick={sliderRef?.slickPrev}
            src="/images/icon-back.png"
            alt=""
          />

          {sortedBlogs &&
            sortedBlogs?.map((blog) => (
              <SwiperSlide key={blog?.id}>
                <div className="blog-box">
                  <Link to="/blog/blog-detail" state={{ ...blog }}>
                    <img
                      className="blog-img"
                      src={imagesBase + getThumbail(blog.theme_img)}
                      alt={blog.title}
                    />

                    <div className="blog-content-container">
                      <span className="blog-date">
                        {formateDate(blog.c_time)}
                      </span>
                      <h1 className="blog-title">{blog.title}</h1>
                    </div>
                  </Link>
                </div>
              </SwiperSlide>
            ))}

          <img
            className="swiper-button-next cursor-pointer"
            ref={navigationNextRef}
            onClick={sliderRef?.slickNext}
            src="/images/icon-next.png"
            alt=""
          />
        </Swiper>
      </div>
    </>
  );
};

export default BlogSlider;
