import "./VideosPage.css";
import MobileHeader from "../MobileHeader/MobileHeader";
import PageTitle from "../PageTitle/PageTitle";

import Carousel from "react-elastic-carousel";
import { useRef } from "react";
import className from "classnames";
import ReactPlayer from "react-player";
import { sendWoogiEvent } from "../../../services/api";
import useIpad from "../../Shared/useIpad";
import { useVideosStore } from "../../../store/videos";

const VideosPage = () => {
  const videos = useVideosStore((store) => store.videos);
  const ref = useRef();
  const { isIpad } = useIpad();

  return (
    <div className="videosPage-container">
      <MobileHeader />
      <PageTitle title="Videos" />
      <div className="vidoesContent-container relative">
        {!isIpad && <NormalPlayer ref={ref} videos={videos} />}
        {isIpad && <IPadPlaye ref={ref} videos={videos} />}
      </div>
    </div>
  );
};

function IPadPlaye({ ref, videos }) {
  return (
    <Carousel
      renderArrow={MyArrow}
      ref={ref}
      itemsToShow={1}
      pagination={false}
    >
      {videos.map((video, index) => (
        <div className="flex flex-col w-full">
          <h1 className="videos-title">{video.name}</h1>
          <div
            className="player-wrapper"
            onClick={() => {
              sendWoogiEvent("VIDEO_PLAY_VIDEOS");
            }}
          >
            <ReactPlayer
              className="react-player"
              width="100%"
              height="100%"
              controls
              url={video.video_url}
              onEnded={() => {
                sendWoogiEvent("VIDEO_FINISH_VIDEO");
              }}
            />
          </div>
        </div>
      ))}
    </Carousel>
  );
}

function NormalPlayer({ ref, videos }) {
  return (
    <div className="flex flex-row relative w-full items-center">
      <div className="videos-row w-full relative">
        <Carousel
          renderArrow={MyArrow}
          ref={ref}
          itemsToShow={3}
          pagination={false}
        >
          {videos.map((video, index) => (
            <div className="flex flex-col w-full">
              <h1 className="videos-title">{video.name}</h1>
              <div
                className="player-wrapper"
                onClick={() => {
                  sendWoogiEvent("VIDEO_PLAY_VIDEOS");
                }}
              >
                <ReactPlayer
                  className="react-player"
                  width="90%"
                  height="100%"
                  controls
                  url={video.video_url}
                  onEnded={() => {
                    sendWoogiEvent("VIDEO_FINISH_VIDEO");
                  }}
                />
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

function MyArrow({ type, onClick, isEdge }) {
  return (
    <button
      disabled={isEdge}
      onClick={onClick}
      className={className("slide-btn self-center", {
        "-rotate-180": type == "NEXT",
      })}
      style={{
        background: "url(images/icon-back.png)",
      }}
    />
  );
}

export default VideosPage;
