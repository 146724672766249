import { create } from "zustand";
import { Story } from "../lib/types";

interface StoryStore {
  story: Story;
  loadStory: (story: Story) => void;
  unloadStory: () => void;
}

export const useStoryStore = create<StoryStore>((set) => ({
  story: {} as Story,
  loadStory: (story: Story) => set(() => ({ story })),
  unloadStory: () => set(() => ({ story: {} as Story })),
}));
