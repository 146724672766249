import "./Leaderboard.css";
import MobileHeader from "../MobileHeader/MobileHeader";
import PageTitle from "../PageTitle/PageTitle";

import "./App.css";
import { getLeaderboardFrame } from "../../../lib";

const Leaderboard = () => {
  return (
    <div className="leaderBoard-container">
      <MobileHeader />
      <PageTitle title="Leaderboard" />

      <iframe
        className="w-full h-screen"
        src={getLeaderboardFrame()}
        name="leader-board"
        title="leader-board"
      />
    </div>
  );
};

export default Leaderboard;
