import dots from "/images/HL_UI-BgCircle-lg.png";
import { apiCallerPost, parseItemImage } from "../../../services/api";
import "./Slider.css";
import { useCallback, useEffect, useRef, useState } from "react";

import { useAuthStore } from "../../../store/auth";
import NewsComponent from "./NewsComponent/NewsComponent";

import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType, SwiperOptions } from "swiper/types";

import "swiper/css";
import { useUIDataStore } from "../../../store/ui";
import Spinner from "../Spinner/Spinner";
import { getLeaderboardFrame, top } from "../../../lib";

import { useTimer } from "use-timer";

type Props = { onShow: (x: boolean) => void; show: boolean };

function getRandomNumber(upperLimit: number) {
  return Math.floor(Math.random() * Math.floor(upperLimit));
}

function getTodayVideo(videos: any[]) {
  const video = videos.find((video) => video.desc === "Today's Display");

  return video;
}

function AssetsSlider({ show, onShow }: Props) {
  const [slides, setSlides] = useState<React.ReactNode[]>([]);
  const videoRef = useRef<HTMLVideoElement | null>(null); // Ref for the video element
  const auth = useAuthStore((store) => store.userAuth);
  const [showNexButton, setShowNextButton] = useState(true);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const sliderRef = useRef<SwiperType | null>();
  const [maxTime, setMaxTime] = useState(0);
  const { start, reset, time, status } = useTimer({
    initialTime: 0,
    endTime: maxTime,
    onTimeOver: () => {
      console.log(maxTime + "s", " time has run out");
      setShowNextButton(true);
    },
  });

  const progress = useUIDataStore((store) => store.gameLoadingProgress);

  // showing the button on the base of progress.
  useEffect(() => {
    if (progress >= 100) {
      setShowNextButton(true);
    }
  }, [progress]);

  const getDataFromServer = useCallback(async () => {
    const resp = await apiCallerPost({
      cmd: "awc_WcConfig.getConfig",
      name: [
        "gms_heroic_force_of_nature_images",
        "gms_portal_videos",
        "skip_portal_modal_max_second",
      ],
      token: auth?.access_token,
    });

    if (!resp.data.data)
      return console.log(
        "returning because awc_WcConfig.getConfig did not give anything"
      );

    const {
      gms_heroic_force_of_nature_images,
      gms_portal_videos,
      skip_portal_modal_max_second,
    } = resp.data.data;

    reset();
    setMaxTime(+skip_portal_modal_max_second);
    start();

    const randomImageIndex = getRandomNumber(
      gms_heroic_force_of_nature_images.length
    );
    const pic = gms_heroic_force_of_nature_images[randomImageIndex];
    const video = getTodayVideo(gms_portal_videos);

    const slide1 = (
      <img
        className="media-frame"
        src={parseItemImage(pic.path)}
        alt={pic.name}
      />
    );

    const slide2 = (
      <Video
        url={video.path}
        onPlay={() => {
          if (status === "RUNNING") {
            setShowNextButton(false);
          }
        }}
        onEnded={() => setShowNextButton(true)}
        videoRef={videoRef}
      />
    );

    const slide3 = (
      <iframe
        className="w-full h-screen max-h-[450px]"
        src={getLeaderboardFrame()}
        name="leader-board"
        title="leader-board"
      />
    );

    const slide4 = <NewsComponent />;

    setSlides([slide1, slide2, slide3, slide4]);
    onShow(true);
  }, [onShow, reset, start, status]);

  useEffect(() => {
    if (auth?.access_token) {
      getDataFromServer();
    }
  }, [auth?.access_token]);

  useEffect(() => {
    const condition =
      import.meta.env.DEV || // for showing button on dev mode locally
      (typeof top?.sw?.isDev == "function" && top?.sw?.isDev()) || // for showing the button for dev users on production
      time >= maxTime; //

    if (condition) {
      setShowNextButton(true);
    }
  }, [maxTime, time]);

  // If the user is not authenticated or there are no slides, return null
  if (!auth?.access_token || !slides.length) return null;

  // Slider settings
  const settings: SwiperOptions = {
    autoplay: false,
    speed: 500,
    allowTouchMove: false,
    autoHeight: true,
  };

  const onChange = (swiper: SwiperType) => {
    setCurrentSlideIndex(swiper.activeIndex);
    if (swiper.activeIndex === 1) {
      if (status === "RUNNING") setShowNextButton(false);
      videoRef.current?.play();
    } else {
      videoRef.current?.pause();
      setShowNextButton(true);
    }
  };

  const onNext = () => {
    if (currentSlideIndex + 1 === slides.length) {
      setCurrentSlideIndex(0);
      onShow(false);
    } else {
      // Check if the current slide is the second slide (video slide)
      if (currentSlideIndex === 1) {
        // Check if the video has been watched completely
        sliderRef.current?.slideNext();
        setCurrentSlideIndex(sliderRef.current!.activeIndex);
      } else {
        sliderRef.current?.slideNext();
        setCurrentSlideIndex(sliderRef.current!.activeIndex);
      }
    }
  };

  const onMove = () => {};

  // Render the component
  if (false && show && auth)
    return (
      <div className="flex items-center justify-center absolute top-0 left-0 z-50 w-screen h-screen bg-blue-950/80">
        <img
          className="absolute top-0 right-0 w-96"
          src={dots}
          alt="circle dots"
        />
        <img
          className="absolute bottom-0 left-0 w-96"
          src={dots}
          alt="circle dots"
        />

        <div className="slider-frame">
          <Swiper
            onSwiper={(s) => (sliderRef.current = s)}
            onSlideChange={onChange}
            slidesPerView={1}
            {...settings}
          >
            {slides.map((slide, index) => (
              <SwiperSlide key={index}>{slide}</SwiperSlide>
            ))}

            <ul className="slick-dots">
              {slides.map((slide, index) => (
                <li
                  key={index}
                  className={currentSlideIndex === index ? "slick-active" : ""}
                  onClick={onMove}
                >
                  <button>{index + 1}</button>
                </li>
              ))}
            </ul>
            {showNexButton && (
              <button className="w-16 h-16 slider-btn" onClick={onNext} />
            )}
            {/* <button className="w-16 h-16 slider-btn" onClick={onNext} /> */}
          </Swiper>
        </div>
      </div>
    );

  return null;
}

const Video = ({
  url,
  onEnded,
  onPlay,
  videoRef,
}: {
  url: string;
  onPlay: React.ReactEventHandler<HTMLVideoElement>;
  onEnded: React.ReactEventHandler<HTMLVideoElement>;
  videoRef: any;
}) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const handleVideoLoaded: React.ReactEventHandler<HTMLVideoElement> = (e) => {
    const event = new CustomEvent("onDownloadComplete", { detail: e });
    (window.top as any).dispatchEvent(event);

    setIsVideoLoaded(true);
  };

  return (
    <div className="media-frame relative">
      {!isVideoLoaded && (
        <div className="absolute z-50 ">
          <Spinner />
        </div>
      )}

      <video
        ref={(ref) => (videoRef.current = ref)}
        width="100%"
        height="100%"
        onPlay={onPlay}
        onEnded={onEnded}
        preload="auto"
        onLoadedData={handleVideoLoaded}
      >
        <source src={parseItemImage(url)} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default AssetsSlider;
