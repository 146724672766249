import { create } from "zustand";
import { Comic } from "../lib/types";

interface ComicsStore {
  comics: Comic[];
  loadComics: (comics: Comic[]) => void;
  unloadComics: () => void;
}

export const useComicsStore = create<ComicsStore>((set) => ({
  comics: [],
  loadComics: (comics: Comic[]) => set(() => ({ comics })),
  unloadComics: () => set(() => ({ comics: [] })),
}));
