import axios from "axios";
import { apiCallerPostLogin } from "../services/api";

export const top = window.top as any;

export const isDev = import.meta.env.DEV;

export const loginUsingLocalStorage = () => {
  const localUser = localStorage.getItem("user");

  if (!localUser) return null;
  const user = JSON.parse(localUser);

  const jwtString = localStorage.getItem("jwt_str");
  if (!jwtString) return;

  const accessTokenString = localStorage.getItem("access_token");
  if (!accessTokenString) return;

  const jwt_str = JSON.parse(jwtString);
  const access_token = JSON.parse(accessTokenString);

  if (!jwt_str || !access_token) return null;

  return {
    user,
    jwt_str,
    access_token,
  };
};

export const loginUsingLocalCookie = async () => {
  const resp = await apiCallerPostLogin({
    cmd: "WoogiOAuth.getPortalUserObj",
  });

  if (resp.data.code == 102) {
    return null;
  }

  const jwt_str = resp.data?.jwt_str;
  const access_token = resp.data?.access_token;

  localStorage.setItem("access_token", JSON.stringify(access_token));
  localStorage.setItem("jwt_str", JSON.stringify(jwt_str));

  return {
    user: {
      uid: resp.data.jwt_info.user.uid,
      id: resp.data.jwt_info.user.id,
      login_name: resp.data.jwt_info.user.login_name,
      nickname: resp.data.jwt_info.user.nickname,
      ...resp.data?.jwt_info?.user,
    },
    jwt_str: resp.data?.jwt_str,
    access_token: resp.data?.access_token,
  };
};

export const anotherLoginRound = async () => {
  if (import.meta.env.PROD) {
    const newUserFromCookies = await loginUsingLocalCookie();

    return newUserFromCookies;
  }

  const user = loginUsingLocalStorage();

  return user;
};

export const hardLogout = async () => {
  if (!isDev) {
    await axios.get("/logout");
  }

  localStorage.clear();
  sessionStorage.clear();
};

export function getThumbail(url: string) {
  var imageExtensions = /\.(jpg|jpeg|png|gif)$/i;
  var webpURL = url.replace(imageExtensions, ".webp");

  return webpURL;
}

export const comicLink = (comic) => {
  const isDev = process.env.NODE_ENV === "development";

  return isDev
    ? // ? `https://portal.americau.com/comic/read?comic_id=${comic.id}`
      `https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe`
    : `/woogi/0.1/static/leaderboard/v3/comic/read.php?comic_id=${comic.id}`;
};

export const getLeaderboardFrame = () =>
  isDev
    ? "https://mainbackend.woogiu.com/client/gmsui/leaderboard-web/build/"
    : "/client/gmsui/leaderboard-web/build/";
