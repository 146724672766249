import React from "react";
import { useLocation, Navigate, useNavigate } from "react-router-dom";
import { imagesBase, sendWoogiEvent } from "../../../../services/api";

import MobileHeader from "../../MobileHeader/MobileHeader";
import { formateDate } from "../../../Shared/dateformater";
import CommentsList from "./CommentsList";
import { useEffect } from "react";

import "./BlogDetail.css";
import { useRef } from "react";

const BlogDetail = () => {
  const { state: post } = useLocation();
  const navigate = useNavigate();
  const containerRef = useRef(null);

  useEffect(() => {
    sendWoogiEvent("PAGE_BLOG_DETAIL");
  }, []);

  if (post == null) {
    return <Navigate to={"/blog"} />;
  }

  return (
    <div className="blogDetail-container">
      <MobileHeader />
      <div className="back-wrap">
        <button className="back-btn" onClick={() => navigate("/blog")}>
          <img alt="back button" src="../images/blog-left-arrow.png" />
        </button>
      </div>

      <img
        className="blogDetail-main-img"
        src={imagesBase + "/" + post.theme_img}
        alt={post.title}
      />
      <h1 className="blogDetail-title">{post.title}</h1>
      <div className="blog-meta-container">
        <span>Date: {formateDate(post.c_time)} |</span>
        <span>Category: {post.kind} |</span>
        <span>Posted By: {post.author} </span>
      </div>
      <div className="blogDetail-content-container">
        <div
          ref={containerRef}
          className="blog_txt_container"
          dangerouslySetInnerHTML={{
            __html: post.content,
          }}
        />
      </div>
      <CommentsList post={post} />
    </div>
  );
};

export default BlogDetail;
