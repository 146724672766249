import { useState } from "react";
import Input from "heroic-ui-library/dist/components/Input";
import GeneralButton from "heroic-ui-library/dist/components/Button";
import { apiCallerPost } from "../../../services/api";

type Props = { onLogin: () => void };

export default function ForgotPasswordForm({ onLogin }: Props) {
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");

  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [tryAgain, setTryAgain] = useState(false);

  const handleSendCode = async () => {
    if (username == "") {
      return setError("Username is required");
    }

    setLoading(true);

    try {
      const formData = {
        cmd: "UserPassword.emailFindBackAccount",
        name: username,
      };

      const response = await apiCallerPost(formData);
      if (response.data.code == 0) {
        setIsCodeSent(true);
        setError("");
      } else if (response.data.code == 105) {
        setError(response.data.msg);
      }
    } catch (error) {
      setError(error.message);
    }

    setLoading(false);
    setTryAgain(false);
  };

  const handlePasswordChange = async () => {
    if (code == "") {
      return setError("Code is required");
    }

    if (password == "") {
      return setError("Password is required");
    }

    if (password != confirmPassword) {
      return setError("Passwords do not match");
    }

    setLoading(true);

    try {
      const formData = {
        new_password: password,
        v_code: code,
        name: username,
        cmd: "UserPassword.checkVerificationCode",
      };

      const response = await apiCallerPost(formData);

      if (response.data.code == 0) {
        setIsCodeSent(false);
        setError("");
        onLogin();
      } else if (response.data.code == 106) {
        setError(response.data.msg);
        setTryAgain(true);
      } else {
        setError(response.data.msg);
      }
    } catch (error) {
      setError(error.message);
    }

    setLoading(false);
  };

  return (
    <div>
      <h1 className="top-heading  brady text-center primary-color">
        RESET PASSWORD
      </h1>
      <div className="pt-6 flex flex-col text-center m-auto">
        {isCodeSent ? (
          <div className=" flex flex-col  text-center">
            <h2
              className="text-[2.5em] leading-tight mb-4 font-bold text-center normal-case acme "
              style={{ color: "rgb(0, 35, 86)" }}
            >
              Check your email for the code
            </h2>

            <div className="form-field mb-4">
              <Input
                value={code}
                onChange={(e) => {
                  setCode(e.currentTarget.value);
                  setError("");
                }}
                type="text"
                placeholder="Code"
              />
            </div>

            <div className="flex flex-row space-x-4">
              <div className="form-field flex">
                <Input
                  value={password}
                  onChange={(e) => setPassword(e.currentTarget.value)}
                  placeholder="New Password"
                  type="password"
                />
              </div>
              <div className="form-field  ">
                <Input
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.currentTarget.value)}
                  placeholder="Confirm Password"
                  type="password"
                />
              </div>
            </div>

            {error && (
              <p className="text-red-600 error acme text-3xl mt-5">
                {error}.
                {tryAgain ? (
                  <span
                    className="ml-2 cursor-pointer underline"
                    onClick={() => handleSendCode()}
                  >
                    Try again
                  </span>
                ) : null}
              </p>
            )}

            <div className="flex flex-row justify-center mt-5 ml-2">
              {!loading ? (
                <GeneralButton
                  btnType="green"
                  className="submit-btn extra-btn"
                  label="Submit"
                  onClick={handlePasswordChange}
                />
              ) : (
                <div className="loader mt-3" />
              )}
            </div>
          </div>
        ) : (
          <>
            <h2
              className="text-[2.5em] leading-tight text-center normal-case acme mb-5"
              style={{ color: "rgb(0, 35, 86)" }}
            >
              Enter your username to receive an email to reset your password
            </h2>
            <div className="form-field mb-4 flex flex-col justify-center items-center w-full">
              <Input
                value={username}
                onChange={(e) => {
                  setUsername(e.currentTarget.value);
                  setError("");
                }}
                type="text"
                name="username"
                placeholder="Username"
              />
              {error && (
                <p className="text-red-600 error acme text-3xl mt-5 ">
                  {error}
                </p>
              )}
            </div>
            <div className="flex flex-row justify-center">
              {!loading ? (
                <GeneralButton
                  btnType="green"
                  className="submit-btn extra-btn"
                  label="Submit"
                  onClick={handleSendCode}
                />
              ) : (
                <div className="loader mt-3" />
              )}
            </div>
          </>
        )}
      </div>
      <div
        onClick={onLogin}
        className="mt-10 text-center w-full second-heading cursor-pointer"
      >
        Back to Login?
      </div>
    </div>
  );
}
