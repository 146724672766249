import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuthStore } from "../../../store/auth";

export default function ProtectedRoute({
  children,
}: {
  children: React.ReactNode;
}) {
  const location = useLocation();
  const accessToken = useAuthStore((store) => store.userAuth?.access_token);

  if (!accessToken) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
}
