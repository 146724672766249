import { useState } from "react";
import "./MeetHeroes.css";
import MobileHeader from "../MobileHeader/MobileHeader";
import PageTitle from "../PageTitle/PageTitle";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect } from "react";
import { sendWoogiEvent } from "../../../services/api";
import KindEint from "../../../assets/meet-heroes/KID-EINSTEIN-min.png";
import MENTALNINJAmin from "../../../assets/meet-heroes/MENTAL-NINJA-min.png";
import FireWall from "../../../assets/meet-heroes/FIREWALL-min.png";
import GRITSTER from "../../../assets/meet-heroes/GRITSTER-min.png";
import deepblue from "../../../assets/meet-heroes/DEEP-BLUE-min.png";
import GoldAngel from "../../../assets/meet-heroes/GOLD-ANGEL-min.png";
import CHANCHITO from "../../../assets/meet-heroes/CHANCHITO-min.png";
import ArkSeed from "../../../assets/meet-heroes/ARKSEED.png";
import DENZILLION from "../../../assets/meet-heroes/DENZILLION-min.png";
import CountDinero from "../../../assets/meet-heroes/COUNT-DINERO-min.png";
import DETECTIVEMR from "../../../assets/meet-heroes/DETECTIVE-MR-E-min.png";
import MIGHTYCHONDRIAN from "../../../assets/meet-heroes/MIGHTY-CHONDRIAN-min.png";
import GENERAL from "../../../assets/meet-heroes/GENERAL-WASHINGTON-min.png";
import tri from "../../../assets/meet-heroes/TRI-GUY-min.png";
import ironHammer from "../../../assets/meet-heroes/IRON-HAMMER-AND-THE-YOUNG-BUILDERS-min.png";
import RePete from "../../../assets/meet-heroes/RE-PETE-min.png";
import Bullseye from "../../../assets/meet-heroes/BULLSEYE-BOT-min.png";
import KARMZAH from "../../../assets/meet-heroes/KARMZAH-min.png";
import Deedles from "../../../assets/meet-heroes/DEEDLES-min.png";
import master from "../../../assets/meet-heroes/MASTER-HEARME-LONGWIND-min.png";

const heroesData = [
  {
    img: KindEint,
    youtubeVideoID: "JuW25j47v-g",
    name: "Kid Einstein",
  },
  {
    img: MENTALNINJAmin,
    youtubeVideoID: "E3BncLoUEKE",
    name: "Mental Ninja",
  },
  {
    img: FireWall,
    youtubeVideoID: "HHpwbTQXky0",
    name: "Firewall",
  },
  {
    img: GRITSTER,
    youtubeVideoID: "vXQZ9um5Ugo",
    name: "Gritster",
  },
  {
    img: deepblue,
    youtubeVideoID: "azBYgcvt8Wk",
    name: "Deep Blue",
  },
  {
    img: GoldAngel,
    youtubeVideoID: "l6IIiEtmiR0",
    name: "Gold Angel",
  },
  {
    img: CHANCHITO,
    youtubeVideoID: "rZh9RvNGQWQ",
    name: "Chanchito",
  },
  {
    img: ArkSeed,
    youtubeVideoID: "DWWmjrK6rvY",
    name: "Ark Seed",
  },
  {
    img: DENZILLION,
    youtubeVideoID: "YjAaxCsZnsc",
    name: "Denzillion",
  },
  {
    img: CountDinero,
    youtubeVideoID: "Sl9V96psMrY",
    name: "Count Dinero",
  },
  {
    img: DETECTIVEMR,
    youtubeVideoID: "Z2lw8jjry3M",
    name: "Detective Mr. E",
  },
  {
    img: GENERAL,
    name: "General Washington",
    youtubeVideoID: "ByUKbYvWa48",
  },
  {
    img: MIGHTYCHONDRIAN,
    youtubeVideoID: "5P9IJo34dQw",
    name: "Mighty Chondrian",
  },
  {
    img: tri,
    youtubeVideoID: "Q16NBGy5JBI",
    name: "Tri Guy",
  },
  {
    img: ironHammer,
    youtubeVideoID: "P2xWdL0FiP4",
    name: "Iron Hammer and the Young Builders",
  },
  {
    img: RePete,
    youtubeVideoID: "BpJ71LNZFhQ",
    name: "Re-Pete",
  },
  {
    img: Bullseye,
    youtubeVideoID: "xp9rA3iX4A4",
    name: "Bullseye Bot",
  },
  {
    img: KARMZAH,
    youtubeVideoID: "kxth_-IGV0I",
    name: "Karmzah",
  },
  {
    img: Deedles,
    youtubeVideoID: "SC38A9LOaTc",
    name: "Deedles",
  },
  {
    img: master,
    youtubeVideoID: "zvuGO9BfrMA",
    name: "Master Hearme Longwind",
  },
];
// Heroes Names

const MeetHeroes = () => {
  const [heroName, setHeroName] = useState(true);
  const [sliderRef, setSliderRef] = useState(null);

  // Onclick any hero name slide to display function
  const slideGoto = (index) => {
    sliderRef?.slickGoTo(index);
  };

  const settings = {
    dots: false,
    customPaging: function (i) {
      return <span>{heroesData[i].name} </span>;
    },
    arrows: false,
    infinite: true,
    lazyLoad: "ondemand",
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: "20px",
  };

  useEffect(() => {
    sendWoogiEvent("BUTTON_SHOW_MEET_HEROES");
  }, []);

  return (
    <div className="meetHeroes-container">
      <MobileHeader />
      <PageTitle title="Meet the Heroes" />
      <img
        onClick={() => setHeroName(!heroName)}
        className="meetHeroes-main-img"
        src="images/vector.png"
        alt=""
      />
      <div className="heroName-container">
        {heroesData.map((name, index) => {
          return (
            <span
              onClick={() => {
                slideGoto(index);
              }}
              className="heroName"
            >
              {name.name} &nbsp; &nbsp; {index !== 19 ? "| " : ""}
            </span>
          );
        })}
      </div>
      <div className="hero-dropdown-nameContainer">
        <select
          onChange={(e) => slideGoto(e.target.value)}
          className="namedrop-down"
        >
          <option selected disabled>
            Select Hero Name
          </option>
          {heroesData.map((name, index) => {
            return <option value={index}> {name.name} </option>;
          })}
        </select>
        <img className="w-8 mr-2" src="/images/chevron.png" alt="" />
      </div>
      <div className="heroes-data-container">
        <div className="left-arrow-container">
          <img
            className="slider-arrow"
            onClick={sliderRef?.slickNext}
            src="images/icon-back.png"
            alt=""
          />
        </div>
        <Slider ref={setSliderRef} {...settings}>
          {heroesData.map((heroData, index) => {
            return (
              <div className="meetHeroes-slide" key={index}>
                <img
                  className="heroes-img-collection"
                  src={heroData.img}
                  alt=""
                />
                <div className="heroes-video">
                  <iframe
                    width="100%"
                    height="100%"
                    src={`https://www.youtube.com/embed/${heroData.youtubeVideoID}`}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  />
                </div>
              </div>
            );
          })}
        </Slider>
        <div className="right-arrow-container meetheroes-slider-right-arrow">
          <img
            className="slider-arrow"
            onClick={sliderRef?.slickPrev}
            src="images/icon-next.png"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default MeetHeroes;
