import { useState } from "react";
import MobileHeader from "../MobileHeader/MobileHeader";
import "./BlogListing.css";
import PageTitle from "../PageTitle/PageTitle";
import { Link } from "react-router-dom";

import dayjs from "dayjs";
import { imagesBase, sendWoogiEvent } from "../../../services/api";
import { useEffect } from "react";
import { getThumbail } from "../../../lib";
import { useBlogStore } from "../../../store/blog";

const PAGE_SIZE = 6;

const BlogListing = () => {
  const blog = useBlogStore((store) => store.blog);
  const [page, setPage] = useState(1);

  useEffect(() => {
    sendWoogiEvent("BUTTON_OPEN_BLOG_LIST");
  }, []);

  return (
    <div className="blogList-container">
      <MobileHeader />
      <PageTitle title="Blog" />
      <div className="blog-cards-container">
        {blog
          .slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE)
          .map((blogPost) => {
            return (
              <div className="blogcard" key={blogPost.id}>
                <div className="blog-img-container">
                  <Link to="blog-detail" state={{ ...blogPost }}>
                    <img
                      className="blog-listing-thumbnail"
                      src={imagesBase + getThumbail(blogPost.theme_img)}
                      alt={blogPost.title}
                    />
                  </Link>
                </div>
                <div className="blog-detail-container">
                  <Link
                    className="blogtitle-link"
                    to="blog-detail"
                    state={{ ...blogPost }}
                  >
                    <h1 className="blogListing-title">
                      {blogPost?.title || "Titlte not found."}
                    </h1>
                  </Link>
                  <p className="blog-date">
                    {dayjs(+blogPost.c_time * 1000).format("MMM DD, YYYY")} |{" "}
                    {blogPost.author}
                  </p>

                  <p className="blog-description">{blogPost.excerpt}</p>
                </div>
              </div>
            );
          })}
      </div>
      <div className="blog-pagintaion">
        {
          <img
            className="blog-left-arrow"
            src="images/blog-left-arrow.png"
            onClick={() => setPage((prev) => prev + 1)}
            alt=""
          />
        }
        <img
          className="blog-right-arrow"
          src="images/blog-right-arrow.png"
          onClick={() => setPage((prev) => (prev - 1 > 0 ? prev - 1 : 1))}
          alt=""
        />
      </div>
    </div>
  );
};

export default BlogListing;
