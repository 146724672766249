type Props = {};

export default function Header({}: Props) {
  return (
    <div className="absolute top-0 w-full">
      <img
        onClick={() => window.open("https://heroicgameday.com/", "_blank")}
        src={"/logo.png"}
        alt="Heroic Game Day Logo"
        className="login-logo"
      />
    </div>
  );
}
