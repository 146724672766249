import React from "react";
import className from "classnames";
import Replies from "./Replies";
import { formatElapsedTime, parseItemImage } from "../../../../services/api";

export default function Comment({ comment, post }) {
  return (
    <div
      key={comment.add_time}
      className={className("commentDetail-container", {
        "pending-comment": comment.enable == 0,
      })}
    >
      <div className="commenter-info">
        <div className="commentor-imgContainer">
          <img
            src={parseItemImage(comment.avatar)}
            className="commentor-img"
            alt={comment.username}
          />
        </div>
      </div>
      <div className="commentData">
        <div className="flex flex-row justify-between">
          <h3 className="commentor-name">{comment.username}</h3>
          {comment.enable == 0 && (
            <span className=" font-sans font-medium text-black/60 italic">
              Pending Approval
            </span>
          )}
        </div>
        <span className="comment-time">
          {formatElapsedTime(+comment.add_time)}
        </span>
        <p className="actual-comment">{comment.content}</p>

        {/* Reply layout */}
        <Replies
          commentId={comment.id}
          post={post}
          comments={comment?.child_comments || []}
        />
      </div>
    </div>
  );
}
