import { ChangeEvent } from "react";
import { top } from "../../../../lib";

export default function CacheClear() {
  const handleCachClear = (e: ChangeEvent<HTMLSelectElement>) => {
    top?.sw?.clearCache &&
      typeof top?.sw?.clearCache == "function" &&
      top?.sw?.clearCache(e.target.value);
  };

  return (
    <select
      defaultValue=""
      onChange={handleCachClear}
      className="  text-black acme rounded mt-3 border-black border-4  text-2xl"
    >
      <option className="acme text-xl" value="">
        Clear All
      </option>
      <option className="acme text-xl" value="HL-images">
        Clear Image Cache
      </option>
      <option className="acme text-xl" value="HL-ui">
        Clear UI Cache
      </option>
      <option className="acme text-xl" value="HL-build">
        Clear Build Cache
      </option>
      <option className="acme text-xl" value="HL-ab">
        Clear AB Cache
      </option>
      <option className="acme text-xl" value="HL-media">
        Clear Media Cache
      </option>
      <option className="acme text-xl" value="HL-media">
        Clear Cache All
      </option>
    </select>
  );
}
