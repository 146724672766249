import { create } from "zustand";
import { News } from "../lib/types";

interface NewsStore {
  news: News[];
  loadNews: (comics: News[]) => void;
  unloadNews: () => void;
}

export const useNewsStore = create<NewsStore>((set) => ({
  news: [],
  loadNews: (news: News[]) => set(() => ({ news })),
  unloadNews: () => set(() => ({ news: [] })),
}));
